const logotext = "LizB";
const meta = {
  title: "Liz B",
  description:
    "I’m Liz Bilashaka - Test Analyst, currently working in Auckland, New Zealand",
};

const introdata = {
  title: "Liz Bilashaka",
  animated: {
    first: "I'm an Automation Engineer",
    second: "I'm a Test Analyst",
    third: "I'm a QA specialist",
  },
  description:
    "Welcome to my Portfolio. This website is built purely on Javascript, React, CSS3 and HTML5. Please find my CV attached below",
  your_img_url: "/img/Porfolio.jpeg",
};

const dataabout = {
  title: " More about me",
  aboutme:
    " I am an ISTQB certified tester at both the foundation and test automation engineer levels. I hold Professional Scrum Master 1 and 2 certifications. My career objective is to leverage my extensive expertise as a dedicated software tester and quality assurance specialist to drive consistent and organized practices, ensuring the achievement of company objectives. I thrive in high-pressure environments, excelling at adapting to new challenges to enhance the organizational brand. I aim to contribute my exceptional interpersonal and collaboration skills to foster a dynamic, goal-driven team environment. Together, we'll create exceptional results and exceed our organizational goals. "
};
const worktimeline = [
  {
    jobtitle: "Test Analyst",
    where: "Foster Moore",
    date: "May 2023 - Present",
  },

  {
    jobtitle: "Software Tester",
    where: "GameZovah Ltd ",
    date: "November 2021 - April 2023",
  },

  {
    jobtitle: "Customer Service Advisor ",
    where: "Xplor Technologies ",
    date: "March – September 2022",
  },
  {
    jobtitle: "Platform Success Manager",
    where: "Digital Oracles",
    date: "November 2020 – January 2021",
  },
  {
    jobtitle: "Business Analyst ",
    where: "AHBS ",
    date: "January 2016 – September 2019",
  },

   {
    jobtitle: "Sales Assistant",
    where: "AHBS",
    date: "June 2014 - November 2015",
  },
];

const skills = [
  {
    name: "Scrum",
    value: 90,
  },
  {
    name: "Jira",
    value: 90,
  },
  {
    name: "Selenium",
    value: 70,
  },
  {
    name: "Test Planning",
    value: 80,
  },
  {
    name: "Debugging",
    value: 80,
  },
  {
    name: "Postman",
    value: 65,
  },
  {
    name: "Technical Writing",
    value: 75,
  },
  {
    name: "Bug tracking",
    value: 70,
  },
  {
    name: "Test implementation",
    value: 90,
  },
  {
    name: "Defect monitoring",
    value: 70,
  },
  {
    name: "Test case preparation",
    value: 70,
  },
  {
    name: "Documentation skills",
    value: 90,
  },
  {
    name: "Regression Testing",
    value: 75,
  },
  {
    name: "Test Planning",
    value: 82,
  },
  {
    name: "Agile Environment",
    value: 70,
  },
  {
    name: "Cross-Platform Testing",
    value: 65,
  },
];

const services = [
  {
    title: "Software Testing",
    description:
      "Software testing is the process of evaluating and verifying that a software product or application does what it is supposed to do. The benefits of testing include preventing bugs, reducing development costs and improving performance. ",
  },

  {
    title: "Business Analyst",
    description:
      "Business analysts assess how organisations are performing and help them improve their processes and systems. They conduct research and analysis in order to come up with solutions to business problems and help to introduce these solutions to businesses and their clients.",
  },

  {
    title: "QA Specialist",
    description:
      "A Quality Assurance Specialist is a professional who is responsible for monitoring, inspecting and proposing measures to correct or improve an organization's final products in order to meet established quality standards.",
  },

  {
    title: "Project Managment",
    description:
      "A project manager is responsible for planning and overseeing projects within an organisation, from the initial ideation through to completion. They coordinate people and processes to deliver projects on time, within budget and with the desired outcomes aligned to objectives.",
  },
];

const education = [
  {
    Institution: "ISTQB",
    Course: "ISTQB Tester Test Automation Engineer (CT-TAE)",
    Date: "September 2023 ",
  },

  {
    Institution: "ISTQB",
    Course: "ISTQB Certified Tester Foundation Level (CTFL)",
    Date: "February 2023",
  },

  {
    Institution: "PSM",
    Course: "Professional Scrum Master™ II (PSM II)",
    Date: "September 2023",
  },
  
  {
    Institution: "PSM",
    Course: "Professional Scrum Master™ I (PSM I)",
    Date: "September 2023",
  },

  {
    Institution: "Skill Soft",
    Course: "Front End Developer",
    Date: "May - October 2022",
  },

  {
    Institution: "Auckland University of Technology",
    Course: "BBUs (Information Systems) 1st Honours",
    Date: "Feb 2021 – 2022",
  },

  {
    Institution: "Auckland University of Technology",
    Course: "BBus (Information Systems)",
    Date: "Feb 2019 – 2021",
  },

  {
    Institution: "Auckland University of Technology",
    Course: "Certificate in Law",
    Date: "Feb 2019 – 2019",
  },
];

const dataportfolio = [
  {
    img: "/img/P1.png",
    desctiption:
      "Create an online Portfolio for yourself with a minimum of least 3 separate page areas.",
    link: "https://liz-bilashaka.github.io/myPortfolio/",
  },
  {
    img: "/img/Quiz.png",
    desctiption:
      "Create an online Quiz of your choice; you will include a ‘How To’ page with clear instructions. You must use appropriate use of JavaScript for the Quiz functionality.",
    link: "https://liz-bilashaka.github.io/secondProject-/",
  },
  {
    img: "/img/E-commerce.png",
    desctiption:
      "our challenge is to build out this e-commerce product page and get it looking as close to the design as possible.",
    link: "https://liz-bilashaka.github.io/react-shopping-project/",
  },
];

const certificationLogo = [
  {
    img:  "/img/ISTQB.png",
  },

  {
    img:  "/img/ISTQB2.png",
  },

  {
    img:  "/img/PSMI.png",
  },

  {
    img:  "/img/PSMII.png",
  },

];

const contactme = [
  {
    YOUR_FONE: "(+64) 21 0606 840",
  },
];

const contactConfig = {
  YOUR_EMAIL: "lizbilashaka@gmail.com",
  YOUR_FONE: "(+64) 21 0606 840",
  description: " ",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_id",
  YOUR_TEMPLATE_ID: "template_id",
  YOUR_USER_ID: "user_id",
};

const socialprofils = {
  github: "https://github.com/Liz-Bilashaka",
  linkedin: "https://www.linkedin.com/in/lizbilashaka/",
};
export {
  meta,
  dataabout,
  dataportfolio,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
  education,
  contactme,
  certificationLogo
};
